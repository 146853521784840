
import {useRouter} from 'vue-router';
import {defineComponent, onMounted} from 'vue';
import identity from '@/api/identity';

export default defineComponent({
    name: 'ApplicationsLogout',
    components: {},
    setup ()
    {
        onMounted(async () =>
        {
            try
            {
                await identity.logout();
                console.log(process.env.VUE_APP_MS_LOGOUT_URL);
                window.location.href = process.env.VUE_APP_MS_LOGOUT_URL;
            }
            catch (error)
            {
                const router = useRouter();
                await router.push({
                    name: 'Error',
                    params: {
                        code: error.response.data.code,
                        message: error.response.data.message
                    }
                });
            }
        });
        return {};
    }
});
